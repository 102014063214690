<template>
	<div class="app-container">
		<Box :type="'warning'" :active="true">
			<div slot="box-body">
				<div class="rawUl">
					<i class="el-icon-warning"></i> &nbsp;{{$t('reports.products.box1')}}
				</div>
			</div>
		</Box>
		<el-card class="box-card" v-loading='bodyLoading'
			:element-loading-text="$t('reports.products.loading') + '...'">
			<div slot="header" class="clearfix">
				<span>{{$t('reports.products.header-1')}}</span>
			</div>
			<div class="center">
				<span class="sub-title">{{$t('reports.products.date-from')}} &nbsp;</span>
				<el-date-picker @change="handleDateChange" v-model="filters.minDate.value" value-format="yyyy-MM-dd"
					type="date">
				</el-date-picker>
				&nbsp;
				<span class="sub-title">{{$t('reports.products.date-to')}} &nbsp; </span>
				<el-date-picker @change="handleDateChange" v-model="filters.maxDate.value" value-format="yyyy-MM-dd"
					type="date">
				</el-date-picker>
			</div>
			<el-tabs v-model="activeReport" v-if="!compareChart.enabled">
				<el-tab-pane :label="$t('reports.products.views')" name="views" :lazy="true">
					<line-chart :chartData="reactiveChart"></line-chart>
				</el-tab-pane>
				<el-tab-pane :label="$t('reports.products.in-cart')" name="cartAdds" :lazy="true">
					<line-chart :chartData="reactiveChart"></line-chart>
				</el-tab-pane>
				<el-tab-pane :label="$t('reports.products.sells')" name="sells" :lazy="true">
					<line-chart :chartData="reactiveChart"></line-chart>
				</el-tab-pane>
				<el-tab-pane :label="$t('reports.products.favorites')" name="favorites" :lazy="true">
					<line-chart :chartData="reactiveChart"></line-chart>
				</el-tab-pane>
				<!-- <el-tab-pane label="Producatori" name="manufacturers" :lazy="true">
				</el-tab-pane> -->
			</el-tabs>
			<line-chart v-if="compareChart.enabled" :chartData="compareChart.data"></line-chart>
			<div class="separator"></div>
			<div class="title">{{$t('reports.products.title-1')}}</div>
			<el-button type="primary" v-if="compareButton" icon="el-icon-sort" size="small" @click="compareProducts">
				{{$t('reports.products.compare-products')}}</el-button>
			<el-button type="danger" v-if="compareButton" icon="el-icon-close"
				:title="$t('reports.products.close-compare')" size="small" @click="resetCompare" plain></el-button>
			<div class="center">
				<el-pagination layout="prev, pager, next, total" :current-page="reactiveCurrentPage"
					:total="table.count" :page-size="table.limit" @current-change="changePage">
				</el-pagination>
			</div>
			<el-table :data="table.list" border style="width: 100%" size="small" @sort-change="handleTableSort"
				@select="handleSelectionChange" @select-all="handleSelectAll" @selection-change="clearSelection"
				ref="summaryTable">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column width="50" align="center">
					<template slot-scope="scope">
						<svg-icon class="product-stats-icon"
							@click.native="openProductReports(scope.row.products_model)" icon-class="stats" />
					</template>
				</el-table-column>
				<el-table-column width="50" align="center">
					<template slot-scope="scope">
						<a :href="scope.row.url" target="_blank">
							<svg-icon icon-class="unlink" />
						</a>
					</template>
				</el-table-column>
				<el-table-column prop="products_model" width="150">
				</el-table-column>
				<el-table-column prop="products_name">
					<template slot-scope="scope">
						{{scope.row.products_name}}
					</template>
				</el-table-column>
				<el-table-column prop="view_count" :render-header="renderPhaseHeader" sortable="custom" width="70"
					align="center">
					<template slot-scope="scope">
						{{$nFormatter(scope.row.view_count, 1)}}
					</template>
				</el-table-column>
				<el-table-column prop="cartAdds" :render-header="renderPhaseHeader" sortable="custom" width="70"
					align="center">
					<template slot-scope="scope">
						{{$nFormatter(scope.row.cartAdds, 1)}}
					</template>
				</el-table-column>
				<el-table-column prop="sells" :render-header="renderPhaseHeader" sortable="custom" width="70"
					align="center">
					<template slot-scope="scope">
						{{$nFormatter(scope.row.sells, 1)}}
					</template>
				</el-table-column>
				<el-table-column prop="favorites" :render-header="renderPhaseHeader" sortable="custom" width="70"
					align="center">
					<template slot-scope="scope">
						{{$nFormatter(scope.row.favorites, 1)}}
					</template>
				</el-table-column>
				<!-- <el-table-column label="Actiuni" width="100px" fixed="right" align="center">
							<template slot-scope="scope">
								<el-button size="mini" type="primary" icon="el-icon-info" plain :disabled="true">Detalii</el-button>
							</template>
						</el-table-column> -->
			</el-table>
			<div class="center">
				<el-pagination layout="prev, pager, next, total" :current-page="reactiveCurrentPage"
					:total="table.count" :page-size="table.limit" @current-change="changePage">
				</el-pagination>
			</div>
		</el-card>
		<product-report v-if="productReportVisible" :productModel.sync="productReportModel"
			:visible.sync="productReportVisible"></product-report>
	</div>
</template>

<script>
	const Box = () =>
		import('vue-info-box-element');
	import {
		EventBus
	} from '@/utils/Bus.js';
	import {
		getProductChartData,
		getProductSummary,
		compareProductsReport
	} from '@/api/reports';

	import Vue from 'vue';

	import {
		currentDate,
		lastMonthDate,
		nFormatter
	} from '@/utils/index';

	import find from 'lodash.find';

	const productReport = () =>
		import('./Components/productReport');

	const lineChart = () =>
		import('@/components/Charts/line');

	const pieChart = () =>
		import('@/components/Charts/pie');


	Vue.prototype.$nFormatter = nFormatter;

	const COLORS = [{
			line: '#29e000',
			background: '#29e00054'
		},
		{
			line: '#8500dd',
			background: '#8500dd6b'
		},
		{
			line: '#0078db',
			background: '#0078db69'
		},
		{
			line: '#f7ab27',
			background: '#f7ab278a'
		},
		{
			line: '#f20909',
			background: '#f209097a'
		}
	];

	export default {
		components: {
			'line-chart': lineChart,
			'product-report': productReport,
			Box,
		},
		props: ['filterQuery'],
		data() {
			return {
				activeReport: 'views',
				reports: {
					views: {

					},
					sells: {

					},
					cartAdds: {

					},
					favorites: {

					}
				},
				filters: {
					minDate: {
						type: 'select',
						value: lastMonthDate(),
					},
					maxDate: {
						type: 'select',
						value: currentDate()
					},
					start: {
						type: 'page',
						value: 0
					}
				},
				table: {
					limit: 50,
					currentPage: 1,
					count: 0,
					list: [],
					sort: {
						prop: 'view_count',
						order: 'desc'
					}
				},
				multipleSelection: [],
				compareButton: false,
				compareChart: {
					enabled: false,
					data: []
				},
				productReportModel: '',
				productReportVisible: false,
				bodyLoading: false
			};
		},
		methods: {
			getChartData(callback) {
				getProductChartData({
					type: this.activeReport,
					startDate: this.filters.minDate.value,
					endDate: this.filters.maxDate.value
				}).then((res) => {
					this.bodyLoading = false;
					if (typeof res === 'undefined' || typeof res.message === 'undefined') return false;
					if (typeof res.message.chart !== 'undefined') {
						this.reports[this.activeReport] = this.constructChart(res.message.chart);
					}
					if (typeof callback === 'function') callback();
				});
			},
			constructChart(data) {
				this.reports[this.activeReport] = {};
				let chartDataSet = {};
				switch (this.activeReport) {
					case 'views':
						chartDataSet = {
							labels: [],
							datasets: [{
								label: this.$t('reports.products.total-view-products'),
								backgroundColor: '#f87979',
								data: []
							}]
						};
						for (const day in data) {
							chartDataSet.labels.push(day);
							chartDataSet.datasets[0].data.push(data[day]);
						}
						break;

					case 'cartAdds':
						chartDataSet = {
							labels: [],
							datasets: [{
								label: this.$t('reports.products.cart-adds'),
								backgroundColor: '#00a8ea',
								data: []
							}]
						};
						for (const day in data) {
							chartDataSet.labels.push(day);
							chartDataSet.datasets[0].data.push(data[day]);
						}
						break;

					case 'sells':
						chartDataSet = {
							labels: [],
							datasets: [{
								label: this.$t('reports.products.sold-products'),
								backgroundColor: '#2bea04',
								data: []
							}]
						};
						for (const day in data) {
							chartDataSet.labels.push(day);
							chartDataSet.datasets[0].data.push(data[day]);
						}
						break;

					case 'favorites':
						chartDataSet = {
							labels: [],
							datasets: [{
								label: this.$t('reports.products.favorite-adds'),
								backgroundColor: '#ff8c00',
								data: []
							}]
						};
						for (const day in data) {
							chartDataSet.labels.push(day);
							chartDataSet.datasets[0].data.push(data[day]);
						}
						break;
				}

				return chartDataSet;
			},
			changePage(page) {
				this.filters.start.value = page * this.table.limit - this.table.limit;
				this.table.currentPage = page;
				this.getProductSummary();
			},
			handleDateChange(val) {
				this.getProductSummary(this.getChartData(this.compareButton ? this.compareProducts : undefined));
			},
			renderPhaseHeader(h, {
				column
			}) {
				const opts = {};
				switch (column.property) {
					case 'view_count':
						opts.content = this.$t('reports.products.views');
						opts.icon = 'eye-2';
						break;

					case 'cartAdds':
						opts.content = this.$t('reports.products.cart-adds-2');
						opts.icon = 'cart-add';
						break;

					case 'sells':
						opts.content = this.$t('reports.products.finished-orders');
						opts.icon = 'cart-sell';
						break;

					case 'favorites':
						opts.content = this.$t('reports.products.favorites-adds');
						opts.icon = 'favorite';
						break;
				}

				return h('el-tooltip', {
					'class': 'dark',
					'props': {
						'content': opts.content,
						'placement': 'top'
					}
				}, [
					h('svg-icon', {
						props: {
							'icon-class': opts.icon,
						},
						style: {
							'font-size': '24px',
							'height': '0.7em'
						}
					})
				]);
			},
			getProductSummary(callback) {
				getProductSummary({
					page: Math.ceil(this.filters.start.value / this.table.limit) + 1,
					startDate: this.filters.minDate.value,
					endDate: this.filters.maxDate.value,
					sort: this.table.sort
				}).then((res) => {
					if (typeof res === 'undefined' || typeof res.message === 'undefined') return false;
					if (typeof res.message.table !== 'undefined') {
						this.table.list = res.message.table;
					}
					if (typeof res.message.count !== 'undefined') {
						this.table.count = res.message.count;
					}
					this.table.currentPage = Math.ceil(this.filters.start.value / this.table.limit) + 1;
					if (typeof callback !== 'undefined') callback();
				});
			},
			handleTableSort({
				column,
				prop,
				order
			}) {
				this.table.sort.prop = prop;
				this.table.sort.order = order;
				this.resetTableOpts();
				this.getProductSummary();
			},
			resetTableOpts() {
				this.table.currentPage = 1;
				this.table.count = 0;
				this.filters.start.value = 0;
			},
			handleSelectionChange(val) {
				if (val.length > 5) {
					this.sbMsg({
						type: 'warn',
						message: this.$t('reports.products.error-select'),
					});
					this.multipleSelection = val.slice(0, 5);
					val.slice(4, -1).forEach((item) => {
						this.$refs.summaryTable.toggleRowSelection(item);
					});
					return false;
				}
				this.multipleSelection = val;
			},
			handleSelectAll(val) {
				if (val.length > 5) {
					this.sbMsg({
						type: 'warn',
						message: this.$t('reports.products.error-select')
					});
				}
				this.multipleSelection = val.slice(0, 5);
				val.slice(4, -1).forEach((item) => {
					this.$refs.summaryTable.toggleRowSelection(item);
				});
			},
			compareProducts() {
				if (this.multipleSelection.length < 2) return false;
				const models = this.multipleSelection.map((item) => item.products_model);
				compareProductsReport({
					models,
					...{
						startDate: this.filters.minDate.value,
						endDate: this.filters.maxDate.value
					}
				}).then((res) => {
					if (typeof res === 'undefined' || typeof res.message === 'undefined') return false;
					this.constructCompareChart(res.message);
				});
			},
			constructCompareChart(data) {
				const chartDataSet = {
					labels: Object.keys(data[Object.keys(data)[0]]),
					datasets: []
				};
				let i = 0;
				for (const model in data) {
					const currentDataSet = {
						label: find(this.table.list, {
							products_model: model
						}).products_name,
						borderColor: COLORS[i]['line'],
						backgroundColor: COLORS[i]['background'],
						data: []
					};
					for (const day in data[model]) {
						currentDataSet.data.push(data[model][day]);
					}
					chartDataSet.datasets.push(currentDataSet);
					i++;
				}
				this.compareChart.data = chartDataSet;
				this.compareChart.enabled = true;
			},
			resetCompare() {
				this.multipleSelection = [];
				this.compareButton = false;
				this.compareChart = {
					enabled: false,
					data: []
				};
				this.$refs.summaryTable.clearSelection();
			},
			clearSelection(vals) {
				if (vals.length < 1) this.resetCompare();
			},
			openProductReports(productModel) {
				this.productReportModel = productModel;
				this.productReportVisible = true;
			}
		},
		computed: {
			reactiveChart() {
				return this.reports[this.activeReport];
			},
			reactiveCurrentPage() {
				return this.table.currentPage;
			}
		},
		watch: {
			multipleSelection() {
				if (this.multipleSelection.length > 1 && this.multipleSelection.length <= 5 || this.multipleSelection
					.length == 45) {
					this.compareButton = true;
				} else {
					this.compareButton = false;
				}
			},
			activeReport(val) {
				// if (Object.keys(this.reports[val]).length > 0) return false
				this.getChartData(false);
			}
		},
		mounted() {
			this.bodyLoading = true;
			this.getProductSummary(this.getChartData);
			EventBus.$on('closeModal', () => {
				this.productReportModel = '';
				this.productReportVisible = false;
			});
		},
		beforeDestroy() {
			EventBus.$off('closeModal');
		}
	};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	@import './src/styles/modules/reports.scss';
</style>